import {GatsbyImage} from "gatsby-plugin-image";
import styled, {keyframes} from 'styled-components'

const zoomOut = keyframes`
  from {
    opacity: 0;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
    filter: blur(16px);
    -webkit-filter: blur(16px);
  }

  50% {
    opacity: 1;
  }
`

const Hero = styled(GatsbyImage)`
  margin: 0 auto;
  max-height: 30rem;
  animation: 1.5s ${zoomOut} ease-in-out;
`
const HeroContainer = styled.div`
  position: relative;
`

const Service = styled.article`
  border-bottom: 1px solid ${(props) => props.theme.colors.caramel};
  padding-top: 1rem;

  a {
    color: ${(props) => props.theme.colors.caramel};
  }
`

const Subtitle = styled.h2`
  color: ${(props) => props.theme.colors.caramel};
  text-align: left;
  padding-inline-start: 16px;
`

const StyledParagraph = styled.p`
  color: ${(props) => props.theme.colors.caramel};
`

export {
    Hero,
    HeroContainer,
    Service,
    Subtitle,
    StyledParagraph,
}
