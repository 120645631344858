import {addHours, addMonths, compareAsc, isFuture, isSameMonth, parse} from 'date-fns'

const filteredByDate = (items) =>
    items
        .filter(
            (item) =>
                isFuture(new Date(item.fields.date))
        )
        .sort(orderByDateAsc)

const filteredByMonth = (items) =>
    items.filter((item) =>
        isSameMonth(addHours(parse(item.fields.date), 2), new Date()),
    )

const filteredByNextMonth = (items) =>
    items.filter((item) =>
        isSameMonth(new Date(item.fields.date), addMonths(new Date(), 1)),
    )

const isInTheSameMonth = (item) =>
    isSameMonth(new Date(item.fields.date), new Date())

function orderByDateAsc(a, b) {
    return compareAsc(a.fields.date, b.fields.date)
}

export {
    filteredByDate,
    filteredByMonth,
    filteredByNextMonth,
    isInTheSameMonth,
    orderByDateAsc
}
