import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import React from 'react'
import { Service } from '../styles/index'

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b>{text}</b>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p style={{ textAlign: 'left' }}>{children}</p>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4 style={{ textAlign: 'left' }}>{children}</h4>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <a href={node.data.uri} target='_blank' rel='noopener noreferrer'>
        {children}
      </a>
    ),
  },
}

const ScheduleService = ({ text }) => (
  <Service>{documentToReactComponents(text, options)}</Service>
)

export default ScheduleService
