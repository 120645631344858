import {filteredByDate} from '../utils/helpers'

const baseUrl = `https://cdn.contentful.com/spaces/${process.env.CONTENTFUL_SPACE_ID}/entries?access_token=${process.env.CONTENTFUL_ACCESS_TOKEN}`

export async function fetchEvents() {
    const response = await fetch(`${baseUrl}&content_type=event&include=1`)
    const {
        items,
        includes: {Asset},
    } = await response.json()
    const images = Asset
        ? Asset.map((asset) => ({
            id: asset.sys?.id,
            url: asset.fields?.file?.url,
        }))
        : []

    return items.reduce((acc, item) => {
        const {id} = item.fields.picture?.sys
        const image = images.filter(img => img.id === id)
        item.fields['imageUrl'] = image.length ? image[0].url : null
        acc.push(item)
        return acc
    }, [])
}

export async function fetchServices() {
    const response = await fetch(`${baseUrl}&content_type=service`)
    const {items} = await response.json()
    return filteredByDate(items)
}

export async function fetchSchedules() {
    const response = await fetch(`${baseUrl}&content_type=schedule`)
    const {
        items,
        includes: {Asset},
    } = await response.json()

    return [items, Asset]
}

export async function fetchInfo() {
    const response = await fetch(`${baseUrl}&content_type=info&include=1`)
    const {items} = await response.json()

    return items
}
